import React from "react";
import { Link } from "gatsby";
import ArrowRight from "../images/svg/arrow-right.svg";

const ButtonArrow = ({ name, linkTo }) => {
  return (
    <div className="flex items-start self-start relative">
      <Link to={linkTo} className="no-underline">
        <div className="flex flex-row items-center self-start buttonarrow cursor-pointer">
          <span className="font-safiaBold text-s18 text-screamingGreen block">
            {name}
          </span>
          <ArrowRight />
        </div>
      </Link>
    </div>
  );
};

export default ButtonArrow;
